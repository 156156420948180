.UserProfileInfoHeader
{
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    margin:0 1rem;
}

@media(max-width: 500px)
{
    .UserProfileInfoHeader
    {
        font-size:1rem;
        margin: 0 0.5rem;
    }   
}