.CurrentMessageProfile
{
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    margin:1rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


@media (max-width: 1000px)
{
    .CurrentMessageProfile
    {
        width: 1rem;
        height: 1rem;
        padding:10%;
    }
}
