.SettingButton
{
    padding:1rem;
    font-size:1rem;
    font-weight: bold;
    background: red;
    color:white;
    border: 2px solid greenyellow;
    border-radius: 10px;
    display: inline;
    margin: 5% 1rem;
    text-align:center !important;
}

.SettingButton:hover
{
    background: orange;
    transition: 0.5s ease-in-out;
    cursor:pointer; 
}

.red
{
    background: red;
}

.chocolate
{
    background: chocolate;
}

.darkgolden
{
    background: darkgoldenrod;
}

.blue
{
    background:#0275d8;
}

@media (max-width:1000px)
{
    .SettingButton
    {
        width:50%;
        font-size:0.7rem;
        margin:1rem auto;
        display: block;
    }
}
