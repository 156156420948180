.container
{
    display:flex;
    flex-direction: column;
    justify-content:space-evenly;
    text-align: center;
    align-items: center;
    height: 100vh;
    overflow-y:visible;
}

.signupBackground
{
    background:url("../../../../assets/backgroundImages/register_background.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
}

.loginBackground
{
    background:url("../../../../assets/backgroundImages/login_background.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
}

.resetPasswordBackground
{
    background:url("../../../../assets/backgroundImages/reset_password_background.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
}

.newPasswordBackground
{
    background:linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url("../../../../assets/backgroundImages/new_password_background.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
}