.PanelIconContainer
{
    width: 2.5rem;
    height: 2.5rem;
    margin-top:1rem !important;
    padding:0.3rem;
    display: inline-block;
}

.PanelIconContainer:hover
{
    background: orange;
    transition: 0.5s ease-in-out;
    border-radius: 50%;
    cursor:pointer;
}

.active
{
    background: orange;
    display: block;
    border-radius:50%;
    cursor:default !important;
}

@media (max-width: 389px)
{
    .PanelIconContainer
    {
        margin:0.3rem !important;
        width:2.3rem;
        height:2.3rem;
    }
}
