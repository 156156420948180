.MessageLabelDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0 1rem;
}

.MessageLabel {
    color: white;
    display: block;
    font-size: 1.1rem;
    width: 80%;
    margin: 0 1rem;
    height: 100%;
}

.NotificationInfo {
    color: orange;
    display: block;
    font-size: 2rem;
    margin: 0 !important;
    width: 10%;
    height: 100%;
}

.NotificationInfoHidden
{
    display: none;
}


@media (max-width: 1000px) {
    .MessageLabel {
        overflow: hidden;
    }
}
