.NewMessageBoxContainer
{
    width: 93%;
    min-height: 5rem;
    max-height: auto;   
    background-color: #025686;
    margin:0 auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    color: white;
    text-align: center;
    z-index: 999;
    font-weight: bold;
}

.NewMessageHeader
{
    font-size: 1rem;
    font-weight: bold;
    margin: 0 auto;
    margin-top:5px;
}

.NewMessageContainer
{
    width: 100%;    
    margin-top: 10px;
    background: transparent;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    margin-bottom: 1rem;
}

.NewMessageReceiverInput
{
    width: 60%;
    font-size: 1.1rem;
    border-radius: 5px;
}

.NewMessageReceiverInput:focus
{
    border: 3px solid orange;
    outline: none;
}

.NewMessageReceiverInput:hover
{
    cursor:pointer;
}

.NewMessageSearchButton
{
    width: 30%;
    background: orange;
    border-radius: 7px;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    border: 3px orangered solid;
}

.NewMessageSearchButton:hover
{
    cursor: pointer;
    background: orangered;
    transition: 0.6s ease-in-out;
    border: 3px gold solid;
}

.newReceiverPageLink
{
    width: 90%;    
    height: 3rem;
    margin-bottom: 1rem;
    background: white;
    outline: none;
    font-weight: bold;
}

@media(max-width: 500px)
{
    .NewMessageSearchButton
    {
        font-size: 0.7rem;
    }
    .NewMessageReceiverInput
    {
        font-size: 0.7rem;
    }
}