.MessageBox
{
    background: #71497C;
    width: 90%;
    height: 5rem;
    margin: 1rem auto;
    display: flex;
    flex-direction: row;
    padding: 0.3rem;
    text-align:center;
    border-radius:10px;
}

.MessageBox:hover
{
    background: #0275d8;
    transition: 0.5s ease-in-out;
}

@media (max-width: 1000px)
{
    .MessageBox
    {
        margin:0.3rem;
        margin-bottom:1rem;
        min-width: 40%;
    }
}