.Input
{
   width:40%;
   height: 2rem;
   font-size:1.1rem;
   border: 3px #bbb solid; 
}

.Input:hover
{
   border: 3px red solid; 
   transition: 0.5s ease-in-out;
}

