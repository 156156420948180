.PageHeader
{
    margin-top: 20vh;
    color:#B7DA07;
    font-size:3rem;
    font-weight: bold;
}

@media (max-width: 1000px)
{
    .PageHeader
    {
        margin-top:1rem;
        font-size:2rem;
    }
}