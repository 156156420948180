.SendMessageButton
{
    display: inline-block;
    width: 20%;
    height: 100%;
    background: #0275d8;
    border-radius: 10px;
    color:white;
    font-size:2rem;
    text-align: center;
    margin: 0 1rem;
    border: 3px solid orange;
}

.SendMessageButton:hover
{
    cursor: pointer;
    background: chocolate;
    transition: 0.5s ease-in-out;
}


@media (max-width: 1000px)
{
    .SendMessageButton
    {
        margin-bottom:0;
    }
}
