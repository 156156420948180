.SenderMessageContent
{
    color:white;
    font-size: 1.3rem;
    text-align:left;
}


@media (max-width: 1000px)
{
    .SenderMessageContent
    {
        font-size:1rem;
    }
}

