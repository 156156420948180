.MessageContentTime
{
    font-size:1.2rem;
    color: white;
    font-weight: bold;
    text-align: right;
    margin:0.5rem;
}

@media (max-width: 1000px)
{
    .MessageContentTime
    {
        font-size:0.8rem;
    }
}