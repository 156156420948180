.MessageInput
{
    width: 80%;
    height:50%;
    font-size: 1.2rem;
    padding: 0.65rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    overflow: auto;
    text-decoration: none;
    text-decoration-style: solid;
    text-underline-offset: 0; 
    border: 3px solid orange;
    border-radius: 10px;
}