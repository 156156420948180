.InfoParagraph 
{
    color: white;
    font-size: 1.4rem;
}

@media (max-width: 400px)
{
    .InfoParagraph
    {
        font-size: 1rem;
    }
}