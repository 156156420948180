.CurrentMessageReceiver
{
    display: block;
    font-size: 1.5rem;
    color:orange;
    margin-top:0.8rem;
    margin-bottom:0;
}


@media (max-width: 1000px)
{
    .CurrentMessageReceiver
    {
        margin-top:1rem;
        font-size: 1.3rem;
    }
}
