.ImageInput
{
    color: white;
    font-size: 1rem;
    padding: 1rem;
    width: 40%;
    margin: 0 auto;
    background: orange;
    border-radius: 20px;
    display:inline-block;
}

.ImageInput:hover
{
    transition: 0.8s ease-in-out;
    background:#0275d8;
    cursor:pointer;
}

@media(max-width: 1000px)
{
    .ImageInput
    {
        font-size:0.6rem;
        width: 50%;
    }
}