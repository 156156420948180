
.ReceiverMessageContainer
{
    width: 40%;
    background: #B08D08;
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
    padding: 1.3rem;
    border-radius: 10px;
    position: relative;
}