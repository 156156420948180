.MessageProfile
{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width:3.5rem;
    height: 3.5rem;
    max-height:100%;
    margin:1rem;
    border-radius: 50%;
    display: block;
}

@media (max-width: 1000px)
{
    .MessageProfile
    {
        padding:0;   
        margin:0.3rem; 
        width: 4rem;
        height: 4rem;
    }
}
