.UserProfile
{
    width: 10rem;
    height: 10rem;
    margin: 0 auto;
    margin-bottom:1rem;
    border-radius: 50%;
    background-size: cover;
}

@media (max-width: 1000px)
{
    .UserProfile
    {
        width:5rem;
        height:5rem;
        margin-bottom:1rem;
    }
}