.MessageArea
{
    width:80%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    text-align:center;
}

.MessageArea::-webkit-scrollbar {
    width: 0.1rem;
}

@media (max-width: 1000px)
{
    .MessageArea
    {
        max-width: 60%;
    }
}