.Box
{
    display: inline-block;
    width: 60vw;
    max-height: auto;
    background-color:rgba(0,0,0,0.4);
    margin-bottom: 4rem;
    border-radius: 20px;
    text-align: center;
    justify-content: center;    
}
