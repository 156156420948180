.SendMessageContainer {
    position: fixed;
    bottom: calc(0px + env(safe-area-inset-bottom));
    background: #48374D;
    width: 80%;
    height: 5.7%;
    z-index: 20;
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    margin-bottom: 0;
}

@media (max-width: 1000px)
{
    .SendMessageContainer
    {
        width: 50%;
    }
}