@font-face {
    font-family: 'Parisienne';
    src: url('../../../../assets/fonts/Parisienne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.header
{
    font-size: 3rem;
    font-weight: 800;
    font-family: 'Parisienne';
}

.headerChat
{
    color: blue;
}

.headerTo
{
    color: white;
}
.headerMe
{
    color:#B7DA07;
}
