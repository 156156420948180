.PageInformation
{
    margin: 2rem 0;
    font-size: 1.5rem;
    color: white;
    font-weight:bold;
}


.PageInformation .Span
{
    color:#B7DA07;
}

@media (max-width: 1000px)
{
    .PageInformation
    {
        font-size:1.1rem;
    }
}