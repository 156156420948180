.MessageHeaderArea
{
    width: 100%;
    height: 10%;
    background: #48374D;
    display: flex;
    flex-direction: row;
}

@media (max-width: 1000px)
{
    .MessageHeaderArea
    {
        height: auto;
    }
}
