.CurrentMessageReceiverStatus
{
    font-size: 1.2rem;
    margin-top: 0.3rem;
}

.Online 
{
    color:greenyellow;
}

.Offline
{
    color:red;
}

@media (max-width: 1000px)
{
    .CurrentMessageReceiverStatus
    {
        font-size:1rem;
    }
}
