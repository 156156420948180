.ContactLink
{
    margin:1rem;
    color: white;
    text-decoration: none;
}

.ContactLink:hover
{
    color:#ddc255;
    transition: 0.5s ease-in-out;
}

.ContactLink p
{
    font-size:1.2rem;
    display: inline;
}

@media(max-width: 1000px)
{
    .ContactLink
    {
        font-size:1rem;
    }
}