.SettingInput
{
    width:60%;
    height: 3%;
    border: 2px solid greenyellow;
    border-radius:5px;
    font-size:1rem;
    color:white;
    background:green;
}

@media(max-width:600px)
{
    .SettingInput
    {
        width:50%;
    }
}