.UsernameLabel
{
    display: block;
    margin: 0.5rem;
    color: orange;
    overflow: hidden;
    font-size:1rem;
}


@media (max-width: 1000px)
{
    .UsernameLabel
    {
        font-size:1rem;
    }
}