.MessageContentArea
{
    width: 100%;
    height: 81.4%;
    background:#3C1A46;
    overflow-y: auto;
    max-height: 81.4% !important;
    padding:0;
    top: 0 !important;
    justify-content: center;
    align-items: center;
    position:relative;
}

.MessageContentArea::-webkit-scrollbar {
    width: 0.5em;
}

.MessageContentArea::-webkit-scrollbar-thumb {
    background-color: transparent;
}

@media(max-width: 500px)
{
    .MessageContentArea
    {
        max-height: 73% !important;
    }
}