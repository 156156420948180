.MessageBar
{
    background: #3C1A46;
    width: 20%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content:stretch;
    margin: 0;
    padding: 0;
    overflow-y: auto;
}

.MessageBar::-webkit-scrollbar {
    width: 0.1em;
}

.MessageBar::-webkit-scrollbar-thumb {
    background-color: transparent;
}

@media (max-width: 1000px)
{
    .MessageBar
    {
        min-width: 50%;
    }
}