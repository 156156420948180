.LabelHeader
{
    color: white;
    font-size: 2rem;
    font-weight: 600;
}

@media (max-width: 400px)
{
    .LabelHeader
    {
        font-size: 1.4rem;
    }
}