.Button
{
    display: inline-block;
    max-width: 30%;
    width: 20%;
    height: 4rem;
    font-size: 1.3rem;
    font-weight: 600;
    background: #0275d8;
    color: white;
    margin: 2rem;
    border: 3px solid orange; 
    border-radius: 5px;
}

.Button:hover
{
    background: chocolate;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}

@media (max-width: 1000px)
{
    .Button
    {
        min-width: 50%;
        margin: 1rem;
        height: 3rem;
        font-size:0.8rem;
    }
}