.Paragraph
{
    color: white;
    font-size: 1.2rem;
}

.Paragraph .link
{
    text-decoration: none;
    color:deepskyblue;
}

.Paragraph .link:hover
{
    text-decoration: none;
    color:red;
    transition: 0.5s ease-in-out;
    cursor:pointer;
}

@media (max-width: 400px)
{
    .Paragraph
    {
        margin: 0;
        color: white;
        font-size: 0.8rem;
        margin-bottom:20px;
    }
    
    .Paragraph .link
    {
        text-decoration: none;
        color:deepskyblue;
    }
    
    .Paragraph .link:hover
    {
        text-decoration: none;
        color:red;
        transition: 0.5s ease-in-out;
        cursor:pointer;
    }
}