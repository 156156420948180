.SettingsBox {
    position: fixed;
    display: flex;
    flex-direction: row;
    bottom: calc(0px + env(safe-area-inset-bottom));
    z-index: 20;
    padding-bottom: 1rem;
    background: #48374D;
    justify-content: space-around;
    width:20%;
}


.SettingsBox:hover
{
    background-blend-mode: color;
}


@media (max-width: 1000px)
{
    .SettingsBox
    {
        width: 50%;
        padding:0;
        margin:0;
    }
}

