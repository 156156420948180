.UserProfileInfo
{
    color:white;
    font-size:1.5rem;
    margin:0.4rem 0;
}

@media (max-width:1000px)
{
    .UserProfileInfo
    {
        font-size:0.7rem;
    }
}