.PanelIcon
{
    height: 100%;
    display: inline;
}

@media (max-width: 1000px)
{
    .PanelIconContainer
    {
        width:2.3rem;
        height:2.3rem;
    }
}
