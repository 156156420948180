.SenderMessageContainer
{
    width: 40%;
    background: #0275d8;
    margin-left: auto;
    color:white;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right:1rem;
    padding: 1.3rem;
    border-radius: 10px;
    position: relative;
}